<template>

	<AddEditForm :headerTitle="$t('pages.cms.addPage')" :backTitle="$t('menu.backToCmsPage')" :onGoBack="onGoBack" :onSave="onSave" :onSaveContinue="onSaveContinue" :showDelete="false">
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')" v-if="editingPage != null">
				<template v-slot:body>
					<CForm>
						<CInput :label="$t('common.name')" :placeholder="$t('pages.cms.enterPageName')"
								v-model="editingPage.systemName" :is-valid="validator" required horizontal>
							<template #invalid-feedback>
								<div class="invalid-feedback">
									{{ $t('pages.cms.pageNameRequired') }}
								</div>
							</template>
							<!-- <small class="requiredInput">(*)</small>						 -->
						</CInput>

						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">
								{{ $t('common.description') }}
							</label>
							<div class="col-sm-9 input-group ckeditor-wrapper">
								<ckeditor v-model="editingPage.body" :config="editorConfig"></ckeditor>
							</div>
						</div>

					</CForm>
				</template>
			</Accordion>


			<Confirmation ref="confirmation"></Confirmation>
		</template>

	</AddEditForm>
</template>

<script>
	import i18n from '@/plugins/i18n'
	import { mapGetters, mapState } from 'vuex'
	import Accordion from '@/components/Accordion.vue'
	import AddEditForm from '@/components/AddEditForm.vue'
	import Confirmation from '@/components/Confirmation.vue'
    import { globalConfigs } from '@/global/index'

	export default {
		name: 'cmsPageAdd',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
				invalidInput: false,
				errorInput: '',
                editorConfig: { ...globalConfigs.CKEditorConfig, fullPage : true },
			}
		},
		components: { AddEditForm, Accordion, Confirmation },
		computed: {
            ...mapState('cms', ['editingPage'])
		},
		methods: {
			validator(val) {
				return val ? val.length > 0 : false
			},
			onGoBack() {
				this.$router.push('/cms/pages/list');
			},
			onSave() {
				this.addNewPage(false);
			},
			onSaveContinue() {
				this.addNewPage(true);
			},
			onCancel() {
				this.$router.push('/cms/pages/list');
			},

			async addNewPage(bContinueEdit) {
				if (!this.validator(this.editingPage.systemName)) {
					this.invalidInput = true;
                    this.errorInput = this.$t('pages.cms.pageNameRequired')
					return;
				}
                var retVal = await this.$store.dispatch("cms/savePage");
				if (retVal != null) {
					if (bContinueEdit) {
						this.$router.push('/cms/pages/edit/' + retVal.id);
					}
					else {
						this.onGoBack();
					}
				}
			},

		},
		mounted() {
            this.$store.dispatch("cms/initNewPage");
		},
	}
</script>
<style scoped>
    .ckeditor-wrapper > div {
        flex: 0 0 100%;
        max-width: 100%;
    }
</style>